$white: #FFFFFF;
$black: #000000;
$grey: #858585;
$green: #06D6A0;
$yellow: #FFD166;
$red: #EF476F;

$light: $white;
$dark: $black;
$primary: $grey;
$secondary: $grey;
$success: $green;
$warning: $yellow;
$danger: $red;

$theme-colors: (
  "light": $light,
  "dark": $dark,
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "warning": $warning,
  "danger": $danger
);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

// Import Bootstrap. This must always be at the bottom of the file.
@import '~bootstrap/scss/bootstrap.scss';

:export {
  light: $light;
  dark: $dark;
  primary: $primary;
  secondary: $secondary;
  success: $success;
  warning: $warning;
  danger: $danger;
}